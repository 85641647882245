import { BrowserRouter, Route } from "react-router-dom";
import Header from "./component/Header/Header";
import MainPage from "./component/MainPage/MainPage";
import MobileWeb from "./component/MobileWeb/MobileWeb";
import SuccessPayment from "./component/SuccessPayment/SuccessPayment";
import FailedPayment from "./component/FailedPayment/FailedPayment";
import useWindowDimensions from "./WindowDimensions";

function App() {
  const { width } = useWindowDimensions();
  return (
    <div style={{ fontFamily: "Rubik" }}>
      <Header />
      {/* {width > 997 ? <MainPage /> : <MobileWeb />} */}
      {/* <SuccessPayment /> */}
      {/* <FailedPayment /> */}
      <BrowserRouter>
        <div>
          <Route
            path="/"
            exact
            component={width > 997 ? MainPage : MobileWeb}
          />
          <Route path="/successPayment/:userInfo" component={SuccessPayment} />
          <Route path="/failedPayment/:userInfo" component={FailedPayment} />
          {/* <Route path="/credentials" exact component={CredentialPage} />
          <Route path="/test" exact component={Test_Select} />
          <Route path="/common" exact component={Common} />
          <Route path="/final" exact component={Final_Page} /> */}
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
