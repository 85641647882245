import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import TagManager from "react-gtm-module";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "semantic-ui-css/semantic.min.css";

import getURLParameter from "./getURLParameter";

const tagManagerArgs = {
  gtmId: "GTM-TH4RWGG",
  dataLayer: {
    event: "winter_bootcamp",
    source: getURLParameter("utm_source"),
    medium: getURLParameter("utm_medium"),
    campaign: "Bootcamp",
    content: getURLParameter("utm_content"),
    "parent name": "",
    "email ID": "",
    "phone number": "",
    "User ID": "",
    IsMobile: "",
    grade: "",
    country: "",
    "Laptop Available": "",
  },
};

// console.log(tagManagerArgs);

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
