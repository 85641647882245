export const winter_bootcamp_Form_proceed = (
  name,
  phone,
  email,
  grade,
  country,
  source,
  medium,
  content
) => ({
  event: "winter_bootcamp_proceed",
  campaign: "Bootcamp",
  source,
  medium,
  content,
  "parent name": name,
  "email ID": email,
  "phone number": phone,
  "User ID": "",
  IsMobile: "",
  grade,
  country,
  "Laptop Available": "",
});
