import React from "react";
import { Image } from "semantic-ui-react";

import "./Header.css";

const Header = () => {
  return (
    <div className="header">
      <Image
        src="images/codeyoung_logo.png"
        className=""
        alt="codeyoung Logo"
        size="small"
      />
    </div>
  );
};

export default Header;
