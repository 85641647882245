import "./Footer.css";

const Footer = () => {
  return (
    <div class="footer mt-5">
      <p>www.codeyoung.com</p>
    </div>
  );
};

export default Footer;
