import React from "react";
import { Icon, Dropdown, Button } from "semantic-ui-react";
import country_list from "./countriesList";
import Axios from "axios";
import getURLParameter from "../../getURLParameter";
import TagManager from "react-gtm-module";

import "./Form.css";
import SuccessPayment from "../SuccessPayment/SuccessPayment";
import { winter_bootcamp_Form_proceed } from "../../datalayers";

const jwt = require("jsonwebtoken");
class Form extends React.Component {
  // constructor(props) {
  //   console.log("props", props);
  //   super(props);
  //   this.props = props;
  state = {
    form: {
      parentName: "",
      childName: "",
      email: "",
      Phone: "",
      childGrade: "",
      course: "",
      country: "India",
      dateBySlot: "",
      timeSlot: {
        winterBootCampBatchStartTime: "",
        winterBootCampBatchEndTime: "",
      },
      winterBootCampBatchId: "",
    },
    formErrorMessage: {
      parentNameError: "",
      contactError: "",
      EmailError: "",
      childNameError: "",
      countryError: "",
    },
    formValid: {
      childNameValid: false,
      contactValid: false,
      Email: false,
      valid: false,
      parentNameValid: false,
      countryValid: false,
    },
    errorMessage: "",
    selectedButton: 0,
    loadSubmit: false,
    paymentModal: false,
  };
  // }

  handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const { form } = this.state;
    this.setState({
      form: { ...form, [name]: value },
      errorMessage: "",
    });
    this.validateField(name, value);
  };

  handleSlotChange = async (name, value) => {
    const { form } = this.state;
    let dateBySlot = "";
    let winterBootCampBatchId = "";
    if (form.childGrade.length > 0) {
      let getDateBySlot = await Axios.post(
        `${process.env.REACT_APP_BOOTCAMP_BASE_URL}/getSlotsByGradeTime`,
        {
          winterBootCampGrade: form.childGrade,
          winterBootCampBatchStartTime: value.winterBootCampBatchStartTime,
          winterBootCampBatchEndTime: value.winterBootCampBatchEndTime,
        },
      );
      if (getDateBySlot.data.availableBatches.length > 0) {
        dateBySlot = getDateBySlot.data.availableBatches[0].jsonOfDates;
        winterBootCampBatchId =
          getDateBySlot.data.availableBatches[0].winterBootCampBatchId;
      }
    }
    this.setState({
      form: {
        ...form,
        [name]: value,
        dateBySlot: dateBySlot,
        winterBootCampBatchId: winterBootCampBatchId,
      },
      errorMessage: "",
    });
    this.validateField(name, value);
  };

  validateField = (fieldName, value) => {
    const { formValid, formErrorMessage, form } = this.state;
    switch (fieldName) {
      case "parentName":
        if (value.length === 0) {
          formValid.parentNameValid = false;
          formErrorMessage.parentNameError = "Field Required";
        } else if (!value.match(/^[A-Za-z " "]{1,30}$/)) {
          formValid.parentNameValid = false;
          formErrorMessage.parentNameError = "Please enter a valid Parent Name";
        } else {
          formValid.parentNameValid = true;
          formErrorMessage.parentNameError = "";
        }
        break;

      case "country":
        if (value.length === 0) {
          formValid.countryValid = false;
          formErrorMessage.countryError = "Field Required";
        } else {
          formValid.countryValid = true;
          formErrorMessage.countryError = "";
        }
        break;

      case "childName":
        if (value.length === 0) {
          formValid.childNameValid = false;
          formErrorMessage.childNameError = "Field Required";
        } else if (!value.match(/^[A-Za-z " "]{1,30}$/)) {
          formValid.childNameValid = false;
          formErrorMessage.childNameError = "Please enter a valid Child Name";
        } else {
          formValid.childNameValid = true;
          formErrorMessage.childNameError = "";
        }
        break;

      case "email":
        if (value.length === 0) {
          formValid.Email = false;
          formErrorMessage.EmailError = "Field Required";
        } else if (
          !value.match(/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+).([a-zA-Z]{2,5})$/)
        ) {
          formValid.Email = false;
          formErrorMessage.EmailError = "Please enter a valid Email";
        } else {
          formValid.Email = true;
          formErrorMessage.EmailError = "";
        }
        break;

      case "Phone":
        if (value.length < 7) {
          formValid.contactValid = false;
          formErrorMessage.contactError = "Please enter a valid Phone Number";
        } else {
          formValid.contactValid = true;
          formErrorMessage.contactError = "";
        }
        break;
      default:
        break;
    }
    if (
      formValid.parentNameValid &&
      formValid.Email &&
      formValid.childNameValid &&
      formValid.contactValid &&
      form.childGrade &&
      form.dateBySlot
    ) {
      formValid.valid = true;
    } else {
      formValid.valid = false;
    }
  };

  selectGrade = async (grade, button) => {
    const { form } = this.state;
    let dateBySlot = "";
    if (form.timeSlot.winterBootCampBatchStartTime.length > 0) {
      let getDateBySlot = await Axios.post(
        `${process.env.REACT_APP_BOOTCAMP_BASE_URL}/getSlotsByGradeTime`,
        {
          winterBootCampGrade: grade,
          winterBootCampBatchStartTime: form.timeSlot.winterBootCampBatchStartTime,
          winterBootCampBatchEndTime: form.timeSlot.winterBootCampBatchEndTime,
        },
      );
      if (getDateBySlot.data.availableBatches.length > 0)
        dateBySlot = getDateBySlot.data.availableBatches[0].jsonOfDates;
    }

    this.setState(
      {
        form: { ...form, childGrade: grade, dateBySlot: dateBySlot },
        selectedButton: button,
      },
      () => {
        this.validateField();
      },
    );
  };

  paymentHandler = async () => {
    let {
      parentName,
      childName,
      email,
      country,
      childGrade,
      winterBootCampBatchId,
      Phone,
      timeSlot: { winterBootCampBatchEndTime, winterBootCampBatchStartTime },
      dateBySlot,
    } = this.state.form;

    let utm = {
      source: getURLParameter("utm_source"),
      medium: getURLParameter("utm_medium"),
      content: getURLParameter("utm_content"),
    };

    const response = await this.loadRazorpay();

    if (!response) {
      alert("Razorpay is not responding");
      return;
    }
    let responses;
    try {
      responses = await Axios.post(
        `${process.env.REACT_APP_BOOTCAMP_BASE_URL}/createOrder`,
        {
          amount: 100000,
          currency: "INR",
          winterBootCampParentName: parentName,
          winterBootCampStudentName: childName,
          winterBootCampContact: Phone,
          winterBootCampEmail: email,
          winterBootCampGrade: childGrade,
          winterBootCampCountry: country,
          winterBootCampBatchId: winterBootCampBatchId,
          ...utm,
        },
      );
      let dataObject = {
        gtmId: "GTM-TH4RWGG",
        dataLayer: winter_bootcamp_Form_proceed(
          parentName,
          Phone,
          email,
          childGrade,
          country,
          utm.source,
          utm.medium,
          utm.content,
        ),
      };
      TagManager.dataLayer(dataObject);
      this.setState({ loadSubmit: false });
      if (responses.sts === false && responses.message === "Batch already filled") {
        this.setState({ errorMessage: "Batch already filled." });
        return;
      }
      if (responses.sts === false) {
        this.setState({ errorMessage: "Internal Server Error." });
        return;
      }
    } catch (err) {
      this.setState({ loadSubmit: false });

      this.setState({ errorMessage: "Internal Server Error." });
      return;
    }

    const orderIdGenerated = await responses;

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
      amount: 100000, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Codeyoung",
      description: "Winter Bootcamp",
      order_id: orderIdGenerated.data.razorResponse.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1

      handler: (response) => {
        if (response) {
          if (response.razorpay_payment_id && response.razorpay_order_id) {
            const userDetails = {
              parentName,
              startTime: winterBootCampBatchStartTime,
              endTime: winterBootCampBatchEndTime,
              dateBySlot,
            };
            const encryptedObj = jwt.sign(userDetails, "process.env.ACCESS_TOKEN_SECRET");

            this.props.redirectTo("success", encryptedObj);
          }
        }
      },
    };

    let rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", (response) => {
      if (response.error.reason === "payment_failed") {
        const userDetails = {
          parentName,
        };
        const encryptedObj = jwt.sign(userDetails, "process.env.ACCESS_TOKEN_SECRET");

        this.props.redirectTo("failure", encryptedObj);
      }
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });

    // const paymentObject = new window.Razorpay(options);
    rzp1.open();
  };

  loadRazorpay = async () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  handleSubmit = () => {
    let { formValid } = this.state;
    if (this.state.form.parentName === "")
      this.setState({ errorMessage: "Please Enter a Parent Name" });
    if (this.state.form.childName === "")
      this.setState({ errorMessage: "Please Enter your Child Name" });
    if (this.state.form.email === "")
      this.setState({ errorMessage: "Please Enter your Email ID" });
    if (this.state.form.country === "")
      this.setState({ errorMessage: "Please choose your country" });
    if (this.state.form.childGrade === "")
      this.setState({ errorMessage: "Please Select a Grade" });
    if (this.state.selectedButton === 0)
      this.setState({ errorMessage: "Please Select a Time Slot" });
    if (formValid.valid) {
      this.paymentHandler();
    } else {
      this.setState({
        loadSubmit: false,
        errorMessage: "Please fill all the required fields",
      });
    }
  };

  togglePaymentModal = () => {
    this.setState((prev) => (prev.paymentModal = !this.state.paymentModal));
  };

  render() {
    const availableTimeSlot = [
      {
        key: "3PM",
        text: "15 : 00 - 16 : 00",
        value: {
          winterBootCampBatchStartTime: "15:00:00",
          winterBootCampBatchEndTime: "16:00:00",
        },
      },
      {
        key: "4PM",
        text: "16 : 00 - 17 : 00",
        value: {
          winterBootCampBatchStartTime: "16:00:00",
          winterBootCampBatchEndTime: "17:00:00",
        },
      },
      {
        key: "5PM",
        text: "17 : 00 - 18 : 00",
        value: {
          winterBootCampBatchStartTime: "17:00:00",
          winterBootCampBatchEndTime: "18:00:00",
        },
      },
      {
        key: "6PM",
        text: "18 : 00 - 19 : 00",
        value: {
          winterBootCampBatchStartTime: "18:00:00",
          winterBootCampBatchEndTime: "19:00:00",
        },
      },
      {
        key: "7PM",
        text: "19 : 00 - 20 : 00",
        value: {
          winterBootCampBatchStartTime: "19:00:00",
          winterBootCampBatchEndTime: "20:00:00",
        },
      },
      {
        key: "8PM",
        text: "20 : 00 - 21 : 00",
        value: {
          winterBootCampBatchStartTime: "20:00:00",
          winterBootCampBatchEndTime: "21:00:00",
        },
      },
    ];

    let {
      parentName,
      childName,
      email,
      country,
      childGrade,
      dateBySlot,
      timeSlot,
      Phone,
    } = this.state.form;

    let {
      parentNameError,
      childNameError,
      EmailError,
      contactError,
      countryError,
    } = this.state.formErrorMessage;

    let { selectedButton, errorMessage, loadSubmit } = this.state;
    return (
      <div>
        {this.state.paymentModal && (
          <SuccessPayment
            open={this.state.paymentModal}
            setOpen={this.togglePaymentModal}
          />
        )}
        <div className="col-12 ">
          <div
            className=" col-12 shadow-lg"
            style={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              bordeRadius: "15px",
              opacity: 1,
            }}
          >
            <div
              className="col-12 text-center h5 pt-3"
              style={{
                // font: "normal normal bold 20px/50px Montserrat",
                letterSpacing: "0.50px",
                color: "#141414",
                textTransform: "capitalize",
                opacity: 1,
              }}
            >
              5 Classes @ <Icon style={{ margin: "0 !important" }} name="rupee" />
              <del>1999</del> 799
              <div>100% Refundable!</div>
              <div> Hurry few slots left!</div>
            </div>
            <form style={{ padding: "5%", color: "black" }} className="col-12">
              <div className="form-group">
                <label className="font-weight-bold">Parent Name:</label>
                <input
                  type="text"
                  placeholder="Enter your name here"
                  className={
                    !parentNameError ? "form-control" : "form-control is-invalid"
                  }
                  name="parentName"
                  value={parentName}
                  onChange={(e) => this.handleChange(e)}
                />
                {parentNameError && (
                  <div className="invalid-feedback font-weight-bold">
                    {parentNameError}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label className="font-weight-bold">Child's Name:</label>
                <input
                  placeholder="Enter your child's name here"
                  type="text"
                  value={childName}
                  className={!childNameError ? "form-control" : "form-control is-invalid"}
                  name="childName"
                  onChange={(e) => this.handleChange(e)}
                />
                {childNameError && (
                  <div className="invalid-feedback font-weight-bold">
                    {childNameError}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label className="font-weight-bold">Parent's Email Address:</label>
                <input
                  type="text"
                  placeholder="Enter your personal email ID here"
                  value={email}
                  className={!EmailError ? "form-control" : "form-control is-invalid"}
                  name="email"
                  onChange={(e) => this.handleChange(e)}
                />
                {EmailError && (
                  <div className="invalid-feedback font-weight-bold">{EmailError}</div>
                )}
              </div>

              <div className="form-group mb-0">
                <label className="font-weight-bold">Country:</label>
              </div>
              <div className="form-group">
                <select
                  className="form-control"
                  name="country"
                  onChange={(e) => this.handleChange(e)}
                  value={country}
                >
                  <option value={"India"} defaultValue>
                    India
                  </option>
                  {country_list.map((country) => {
                    return (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    );
                  })}
                </select>
                {countryError && (
                  <div className="text-danger font-weight-bold">{countryError}</div>
                )}
              </div>

              <div className="form-group">
                <label className="font-weight-bold">Parent's Phone Number:</label>
                <input
                  placeholder="Enter your Mobile Number"
                  type="number"
                  value={Phone}
                  className={!contactError ? "form-control" : "form-control is-invalid"}
                  name="Phone"
                  onChange={(e) => this.handleChange(e)}
                />
                {contactError && (
                  <div className="text-danger font-weight-bold">{contactError}</div>
                )}
              </div>

              <div className="form-group mb-0">
                <label className="font-weight-bold">Select Child's Grade:</label>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-3 ">
                  <button
                    type="button"
                    className={
                      selectedButton === 1 || childGrade === "1-3"
                        ? "btn btn-dark btn-block"
                        : "btn btn-outline-secondary btn-block"
                    }
                    onClick={() => this.selectGrade("1-3", 1)}
                  >
                    1-3
                  </button>
                </div>

                <div className="form-group col-lg-3 ml-auto">
                  <button
                    type="button"
                    className={
                      selectedButton === 2 || childGrade === "4-6"
                        ? "btn btn-dark btn-block"
                        : "btn btn-outline-secondary btn-block"
                    }
                    onClick={() => this.selectGrade("4-6", 2)}
                  >
                    4-6
                  </button>
                </div>

                <div className="form-group col-lg-3 ml-auto">
                  <button
                    type="button"
                    className={
                      selectedButton === 3 || childGrade === "7-9"
                        ? "btn btn-dark btn-block"
                        : "btn btn-outline-secondary btn-block"
                    }
                    onClick={() => this.selectGrade("7-9", 3)}
                  >
                    7-9
                  </button>
                </div>
              </div>
              <div className="form-group mb-0">
                <label className="font-weight-bold">Select Time Slot:</label>
              </div>
              <div className="form-group">
                <Dropdown
                  name="timeSlot"
                  onChange={(e, { value }) => this.handleSlotChange("timeSlot", value)}
                  fluid
                  selection
                  placeholder="Choose a slot"
                  options={availableTimeSlot}
                ></Dropdown>
              </div>
              <div className="form-group mb-0">
                <label className="font-weight-bold">Scheduled Dates:</label>
              </div>
              <div>
                {dateBySlot.length > 0 && (
                  <div
                    className="col-12 row justify-content-center w-100"
                    style={{ margin: 0, padding: 0 }}
                  >
                    {dateBySlot.split(",").map((dates) => {
                      return (
                        <Button
                          size="mini"
                          disabled
                          className="col-4 col-md-4 mt-2 text-white"
                          style={{
                            width: "fit-content",
                            opacity: 1,
                            backgroundColor: "#f2711c",
                            color: "#ffffff !important",
                            marginRight: "0 !important",
                          }}
                        >
                          {dates}
                        </Button>
                      );
                    })}
                  </div>
                )}
                {timeSlot.winterBootCampBatchStartTime.length > 0 &&
                  childGrade.length > 0 &&
                  dateBySlot.length === 0 && (
                    <div className="text-danger">Slots filled for the selected time.</div>
                  )}
                <div style={{ fontSize: "12px" }}>
                  <Icon name="info circle" color="red" />
                  Dates are available on the time slot you choose.
                </div>
              </div>
              <div
                style={{
                  marginTop: "6%",
                  marginBottom: "10px",
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    this.setState({ loadSubmit: true });
                    this.handleSubmit();
                  }}
                  style={{ backgroundColor: "#FFEB00" }}
                  className="btn btn-block font-weight-bold "
                >
                  {loadSubmit ? (
                    <span>
                      <Icon name="hourglass end" loading />
                      Loading
                    </span>
                  ) : (
                    "Proceed to Pay"
                  )}
                </button>
                {errorMessage && (
                  <p className="text-danger font-weight-bold text-center mt-2">
                    {errorMessage}
                  </p>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Form;
