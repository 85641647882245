const gradeContent = {
  scratch: {
    content: [
      "Introduction to motion block, Project - Chasing game",
      "Concept - Look and sound block, Project - Dance Party",
      "Concept - More look block, Project - Interactive story play",
      "Concept - Interaction with sprite, Project - Interaction with sprite",
      "Exam (30 mins)+ viva (15 mins)+ Path ahead (15 mins)",
    ],
  },
  mitApp: {
    content: [
      "Introduction to MIT app - purpose, application & uses, Project - Show image App",
      "Concept - Introducing to components , Project - Text-to-speech",
      "Concept - Exploring built in blocks , Project - Calculator",
      "Concept - Using Accelerometer , Project - Bouncing Balls",
      "Exam (30 mins)+ viva (15 mins)+ Path ahead (15 mins)",
    ],
  },
  webDev: {
    content: [
      "Introduction to Web development - basics of HTML, paragraph and styling, Project - My Webpage",
      "Concept - Formatting in HTML , Project - Covid-19 info webpage",
      "Concept - HTML text & phrases , Project - My favourite novel webpage",
      "Concept - HTML images & tables , Project - Covid-19 Tracker webpage",
      "Exam (30 mins)+ viva (15 mins)+ Path ahead (15 mins)",
    ],
  },
};

export default gradeContent;
