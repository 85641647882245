import React, { useState, useEffect } from "react";
import { Card, Icon } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import "./SuccessPayment.css";

const jwt = require("jsonwebtoken");

const SuccessPayment = (props) => {
  const { userInfo } = useParams();
  const [user, setUser] = useState({});

  useEffect(() => {
    const decoded = jwt.verify(userInfo, "process.env.ACCESS_TOKEN_SECRET");
    setUser(decoded);
  }, [userInfo]);
  return (
    <div className="text-center mx-auto">
      <Icon
        name="check circle"
        color="green"
        size="huge"
        className="success-icon-badge"
      />
      <Card className="mx-auto" style={{ width: "600px", display: "flex" }}>
        <div className="paddingContent">
          <h1>Congratulation, {user.parentName}</h1>
          <div>
            Your payment has been successfully received. Kindly grant us time until one of
            our sales executives gets in touch with you to share your class and the
            joining details. Here are your slot details that you booked with us:
          </div>
          <div>
            Time Slot : {user.startTime} - {user.endTime}
          </div>
          <div>Dates : {user.dateBySlot}</div>
          <div>Spread the word! Invite friends & family by sharing this link:</div>
          <a target="_blank" href="https://www.winterbootcamp.codeyoung.com">
            www.winterbootcamp.codeyoung.com
          </a>
        </div>
      </Card>
    </div>
  );
};

export default SuccessPayment;
