import React, { useState } from "react";
import { Image, Card, Dropdown, Icon } from "semantic-ui-react";
import Form from "../Form/Form";
import { useHistory } from "react-router-dom";
import gradeContent from "../../course-content";

import "./MobileWeb.css";

const coursesDropDown = [
  {
    key: "Grade 1-3",
    text: "Grade 1-3 | Scratch Language",
    value: "scratch",
  },
  {
    key: "Grade 4-6",
    text: "Grade 4-6 | MIT App Inventor",
    value: "mitApp",
  },
  {
    key: "Grade 7-9",
    text: "Grade 7-9 | Web Dev",
    value: "webDev",
  },
];

const MobileWeb = () => {
  const history = useHistory();
  const [courseSelected, setCourseSelected] = useState("scratch");

  const redirectTo = (status, cipher) => {
    switch (status) {
      case "success":
        history.push(`/successPayment/${cipher}`);
        return;
      case "failure":
        history.push(`/failedPayment/${cipher}`);
        return;
      default:
        return;
    }
  };

  return (
    <div className="mobile-page">
      <div className="orangeBackground">
        <Image src="images/backgroundMobile.png" alt="orangeBackground"></Image>
        <div className="Imagecontent text-center">
          <div>INTRODUCING</div>
          <div>WINTER BOOTCAMP - 2020</div>
        </div>
      </div>
      <div className="mt-2 mb-2">
        <Form redirectTo={redirectTo} />
      </div>
      <div className="bootcampOffers">
        <h3>What does this bootcamp offer ?</h3>
        <div className="col-12 col-lg-6 offset-lg-0 text-lg-left text-center">
          <div className="row mt-3">
            <div className="col-lg-6  p-2">
              <div className="shadow p-3 m-2 information-box">
                <div className="row">
                  <div className="col-lg-3 pr-0 pl-0">
                    <Image
                      src="images/mentors.png"
                      alt="mentos"
                      size="tiny"
                      verticalAlign="middle"
                    />
                  </div>
                  <div className="col-lg-7 offset-lg-1 lg-ml-3">
                    <h5 className="font-weight-bold mb-0 mt-2 pl-3">
                      Masterclasses From
                    </h5>
                    <div className="pl-3 font-weight-bold">Top Quality Mentors</div>
                  </div>
                  {/* <div className="col-lg-9 offset-lg-1 ml-auto">Interactive Session</div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-2">
              <div className="shadow p-3 m-2 information-box">
                <div className="row">
                  <div className="col-lg-3 pr-0">
                    <Image
                      src="images/batches.png"
                      alt="mentos"
                      size="tiny"
                      verticalAlign="middle"
                    />
                  </div>
                  <div className="col-lg-7 offset-lg-1 lg-ml-3">
                    <h5 className="font-weight-bold mb-0 mt-2 pl-3">
                      Learning in Batches
                    </h5>
                    <div className="pl-3 font-weight-bold">of upto 5 students</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-6  p-2">
              <div className="shadow p-3 m-2 information-box">
                <div className="row">
                  <div className="col-lg-3 pr-0">
                    <Image
                      src="images/handsOn.png"
                      alt="mentos"
                      size="tiny"
                      verticalAlign="middle"
                    />
                  </div>
                  <div className="col-lg-7 offset-lg-1 lg-ml-3">
                    <h5 className="font-weight-bold mb-0 mt-2 pl-3">
                      Completely Hands On
                    </h5>
                    <div className="pl-3 font-weight-bold">Learning Experience</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-2">
              <div className="shadow  p-3 m-2 information-box">
                <div className="row">
                  <div className="col-lg-3 pr-0 ">
                    <Image
                      src="images/certificate.png"
                      alt="mentos"
                      size="tiny"
                      verticalAlign="middle"
                    />
                  </div>
                  <div className="col-lg-7 offset-lg-1 lg-ml-3">
                    <h5 className="font-weight-bold mb-0 mt-2 pl-3">
                      Participation Certificate from
                    </h5>
                    <div className="pl-3 font-weight-bold">TOSC IIT Kanpur</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="learnthrough">
        <h3>What will my child learn through this bootcamp ?</h3>
        <div>
          <Dropdown
            fluid
            selection
            options={coursesDropDown}
            defaultValue="scratch"
            onChange={(e, { value }) => setCourseSelected(value)}
          ></Dropdown>
        </div>
        <div className="text-center h4 conceptToLearn mt-2">CONCEPT TO LEARN</div>
        {gradeContent[courseSelected].content.map((course, index) => {
          return (
            <div className="text-center" key={index}>
              <div className="h5 font-weight-bold">Session {index + 1}</div>
              <div>{course}</div>
            </div>
          );
        })}
      </div>
      <div className="mt-2 mb-2">
        <Card.Group centered>
          <Card>
            <div className="text-center support-card">
              <Icon name="headphones" size="big" />
              <div>
                For any doubts or quieries feel free to contact us at +9190190 31776 or
                drop us an email at support@codeyoung.com
              </div>
            </div>
          </Card>
        </Card.Group>
      </div>
    </div>
  );
};

export default MobileWeb;
