const getURLParameter = (param) => {
  const withoutOriginURL = window.location.href.substring((window.location.origin).length + 1);

  if (withoutOriginURL.split("?")[1]) {
    const urlVariableString = withoutOriginURL.split("?")[1].split("&");
    for (let i = 0; i < urlVariableString.length; i++) {
      const parameterName = urlVariableString[i].split("=");
      if (parameterName[0] === param) {
        return parameterName[1];
      }
    }
    return "";
  }
  return "";
};

export default getURLParameter;
