import React, { useState } from "react";
import { Image, Button, Card, Icon, Container } from "semantic-ui-react";
import gradeContent from "../../course-content";
import { useHistory } from "react-router-dom";
import Form from "../Form/Form";
import Footer from "../Footer/Footer";

import "./MainPage.css";

const MainPage = () => {
  const history = useHistory();
  const [courseSelected, setCourseSelected] = useState("scratch");

  const redirectTo = (status, cipher) => {
    switch (status) {
      case "success":
        history.push(`/successPayment/${cipher}`);
        return;
      case "failure":
        history.push(`/failedPayment/${cipher}`);
        return;
      default:
        return;
    }
  };

  return (
    <div className="main-page">
      <div className="orangeBackground">
        <Image src="images/orangeBackgroundTent.png" alt="orangeBackground"></Image>
        <div className="Imagecontent">
          <h3>INTRODUCING</h3>
          <div className="h1">WINTER BOOTCAMP - 2020</div>
          <div>
            Curated 5 live classes program with the best quality mentors to get your
          </div>
          <div>
            Kid to kickstart their coding journey @{" "}
            <Icon style={{ margin: "0 !important" }} name="rupee" />
            <del>1999</del> 799 /- ( 60% off for limited time
          </div>
          <div>
            period) only. And oh, its 100% refundable* , anytime before the start of
            second class!
          </div>
        </div>
        <div className="formPlacement">
          <Form redirectTo={redirectTo} />
          <div className="mt-2 mb-2">
            <div className="col-12" style={{ paddingTop: "5%" }}>
              <div className="col-12">
                <Card>
                  <div className="text-center support-card">
                    <Icon name="headphones" size="big" />
                    <div>
                      For any doubts or quieries feel free to contact us at +9190190 31776
                      or drop us an email at support@codeyoung.com
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <div className="bootcampOffers">
          <h3>What does this bootcamp offer ?</h3>
          <div className="col-12 col-lg-6 offset-lg-0 text-lg-left text-center">
            <div className="row mt-3">
              <div className="col-lg-6  p-2">
                <div className="shadow p-3 m-2 information-box">
                  <div className="row">
                    <div className="col-lg-3 pr-0">
                      <Image src="images/mentorsBig.png" alt="mentos" size="big" />
                    </div>
                    <div className="col-lg-7 offset-lg-1 lg-ml-2">
                      <div className="font-weight-bold mb-0 mt-2 pl-3">
                        MasterClasses From
                      </div>
                      <div className="pl-3 font-weight-bold">Top Quality Mentors</div>
                    </div>
                    {/* <div className="col-lg-9 offset-lg-1 ml-auto">Interactive Session</div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 p-2">
                <div className="shadow p-3 m-2 information-box">
                  <div className="row">
                    <div className="col-lg-3 pr-0">
                      <Image src="images/batchesBig.png" alt="mentos" size="big" />
                    </div>
                    <div className="col-lg-7 offset-lg-1 lg-ml-2">
                      <div className="font-weight-bold mb-0 mt-2 pl-3">
                        Learning in Batches of
                      </div>
                      <div className="pl-3 font-weight-bold">upto 5 students</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-6  p-2">
                <div className="shadow p-3 m-2 information-box">
                  <div className="row">
                    <div className="col-lg-3 pr-0">
                      <Image src="images/handsOnBig.png" alt="mentos" size="big" />
                    </div>
                    <div className="col-lg-7 offset-lg-1 lg-ml-3">
                      <div className="font-weight-bold mb-0 mt-2 pl-3">
                        Completely Hands On Learning
                      </div>
                      <div className="pl-3 font-weight-bold">Experience</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 p-2">
                <div className="shadow  p-3 m-2 information-box">
                  <div className="row">
                    <div className="col-lg-3 pr-0 ">
                      <Image src="images/certificateBig.png" alt="mentos" size="big" />
                    </div>
                    <div className="col-lg-8 offset-lg-1 lg-ml-2">
                      <div className="font-weight-bold mb-0 mt-2 pl-1">
                      Participation Certificate from
                      </div>
                      <div className="pl-1 font-weight-bold">TOSC IIT Kanpur</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="learnthrough">
          <h3>What will my child learn through this bootcamp ?</h3>
          <div className="col-lg-6 mb-3">
            <Button.Group size="large" fluid>
              <Button
                color="orange"
                onClick={() => {
                  setCourseSelected("scratch");
                }}
                basic={courseSelected === "scratch" ? true : false}
              >
                Grade 1-3
                <div
                  // className={` font-weight-light mt-2 ${
                  //   courseSelected === "scratch" ? "text-muted" : "text-dark"
                  // } `}
                  className="font-weight-light mt-2 text-muted"
                  style={{ fontSize: "15px" }}
                >
                  Scratch
                </div>
              </Button>
              <Button
                color="orange"
                onClick={() => {
                  setCourseSelected("mitApp");
                }}
                basic={courseSelected === "mitApp" ? true : false}
              >
                Grade 4-6
                <div
                  className="text-muted font-weight-light mt-2"
                  style={{ fontSize: "15px" }}
                >
                  MIT app inventor
                </div>
              </Button>
              <Button
                color="orange"
                onClick={() => {
                  setCourseSelected("webDev");
                }}
                basic={courseSelected === "webDev" ? true : false}
              >
                Grade 7-9
                <div
                  className="text-muted font-weight-light mt-2"
                  style={{ fontSize: "15px" }}
                >
                  Web development
                </div>
              </Button>
            </Button.Group>
            <Card className="w-100">
              <Container className="mt-2 mb-2">
                {gradeContent[courseSelected].content.map((course, index) => {
                  return (
                    <Container key={index}>
                      <div className="row mt-2 mb-2">
                        <div className="h5 col-3">Session {index + 1}</div>
                        <div className="col-9 float-right">{course}</div>
                      </div>
                    </Container>
                  );
                })}
              </Container>
            </Card>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MainPage;
