import React, { useState, useEffect } from "react";
import { Card, Icon } from "semantic-ui-react";
import "./FailedPayment.css";

import { useParams } from "react-router-dom";

const jwt = require("jsonwebtoken");

const FailedPayment = () => {
  const { userInfo } = useParams();
  const [user, setUser] = useState({});

  useEffect(() => {
    const decoded = jwt.verify(userInfo, "process.env.ACCESS_TOKEN_SECRET");
    setUser(decoded);
  }, [userInfo]);
  return (
    <div className="text-center mx-auto">
      <Icon
        name="warning circle"
        color="yellow"
        size="huge"
        className="success-icon-badge"
      />
      <Card className="mx-auto" style={{ width: "600px", display: "flex" }}>
        <div className="paddingContent">
          <h1>Payment Failure</h1>
          <div>
            Dear {user.parentName}, it look like the transaction you just
            attempted to make has failed. Make sure you are entering the details
            correctly.
            {/* Click the payment link below and try again. */}
          </div>
          {/* <h1>Payment Link</h1> */}
        </div>
      </Card>
    </div>
  );
};

export default FailedPayment;
